<template>
<div class="main-content menus">
    <h1>Manage Menus</h1>
    <div class="filtered-list-subhead"></div>
    <div class="filtered-list">
        <div v-if="showFilters" class="filtered-list-sidebar">
            <label>Select a menu to edit...</label>
            <select v-model="selectedMenuId" @change="getItem()">
                <option value="">Select...</option>
                <option v-for="(menu, index) in menus" :value="menu.menu_id">{{ menu.description }}</option>
            </select>
            <div class="button button-dark" @click="getItem('new')">Create A Menu</div>
            <div class="button button-dark" @click="newMenuItem()">Create A Menu Item</div>
            <div class="note">{{ filterMessage }}</div>
        </div>
        <div class="filtered-list-item">
            <div class="one-two-columns">
                <LabelRequired label="Menu Code" :condition="detail.menu_code"/>
                <LabelRequired label="Description" :condition="detail.description"/>
                <input type="text" v-model="detail.menu_code">
                <input type="text" v-model="detail.description">
            </div>
            <div class="filtered-list-item-two-columns">
                <div>
                    <div class="draggable-list-heading">
                        <label>Available menu items</label>
                        <label class="note">Drag to include in this menu &rarr; </label>
                    </div>
                    <Draggable
                        class="draggable-list list-left"
                        ghost-class="ghost"
                        handle=".handle"
                        v-model="availableMenuItems"
                        group="menuItems"
                        @start="drag=true"
                        @end="drag=false"
                        item-key="id">
                        <template #item="{ element }">
                            <div class="draggable-list-item">
                                <font-awesome-icon icon="grip-horizontal" class="handle" />
                                {{ element.name }}
                            </div>
                        </template>
                    </Draggable>
                </div>
                <div>
                    <div class="draggable-list-heading">
                        <label>Included menu items</label>
                        <label class="note">Drag to set menu order &darr;</label>
                    </div>
                    <Draggable
                        class="draggable-list list-right"
                        ghost-class="ghost"
                        handle=".handle"
                        v-model="menuItems"
                        group="menuItems"
                        @start="drag=true"
                        @end="drag=false"
                        item-key="id">
                        <template #item="{ element }">
                            <div class="draggable-list-item">
                                <font-awesome-icon icon="grip-horizontal" class="handle" />
                                {{ element.name }}
                            </div>
                        </template>
                    </Draggable>
                </div>
            </div>
            <div class="control-buttons">
        		<div class="button cancel" @click="cancelEditItem()">Cancel Edit</div>
        		<div :class="['button', { disabled: !selectedMenuId } ]" @click="saveItem()"><LabelWithLoadingIcon :loading="saving" label="Save Menu"/></div>
                <DeleteButton label="Delete Menu" :disabled="!selectedMenuId" :loading="deleting" @doDelete="deleteMenu" />
                <Message :message="message" :error="errorStatus" delay="3" :key="message + saveCount" />
            </div>
        </div>
    </div>

    <div v-if="editingMenuItem" class="modal">
        <div class="modal-inner">
            <div class="modal-title">{{ menuItem.menu_item_id == 'new' ? 'Create' : 'Edit' }} A Menu Item</div>
            <div class="modal-inner-content">
                <LabelRequired label="Label" :condition="menuItem.label"/>
                <input type="text" v-model="menuItem.label"/>
                <LabelRequired label="Link Type" :condition="menuItem.link_type"/>
                <select v-model="menuItem.link_type">
                    <option value="">Select...</option>
                    <option value="ROUTER">Router Link</option>
                    <option value="EXTENRAL">External Link</option>
                </select>
                <LabelRequired label="Link Value" :condition="menuItem.link_value"/>
                <input type="text" v-model="menuItem.link_value"/>
                <label>Sub Menu</label>
                <select v-model="menuItem.sub_menu_id">
                    <option value="">Select...</option>
                    <option v-for="(menu, index) in menus" :value="menu.menu_item_id">{{ menu.description }}</option>
                </select>
            </div>
            <div class="modal-controls control-buttons">
                <div class="button cancel" @click="editingMenuItem = false">Cancel</div>
                <div class="button" @click="saveMenuItem()"><LabelWithLoadingIcon :loading="savingMenuItem" label="Save" /></div>
                <DeleteButton label="Delete" :disabled="!menuItem.menu_item_id || menuItem.menu_item_id == 'new'" :loading="deleting" @doDelete="deleteMenuItem" />
                <Message :message="saveMenuItemMessage" :error="errorStatus" delay="3" :key="saveMenuItemMessage + saveCount" />
            </div>
        </div>
    </div>

</div>
</template>

<script>
import LabelRequired from '@/components/LabelRequired.vue'
import LabelWithLoadingIcon from '@/components/LabelWithLoadingIcon.vue'
import DeleteButton from '@/components/DeleteButton.vue'
import Message from '@/components/Message.vue'
import Draggable from 'vuedraggable'
import { mapGetters } from 'vuex'
import { mapActions } from 'vuex'
export default {
    name: 'Menus',
    components: {
        LabelRequired,
        LabelWithLoadingIcon,
        DeleteButton,
        Message,
        Draggable
    },
    data() {
        return {
            loading: false,
            message: '',
            filterMessage: '',
            menus: [],
            selectedMenuId: '',
            detail: {},
            menuItems: [],
            menuItemsSearch: '',
            availableMenuItems: [],
            availableMenuItemsSearch: '',
            errorStatus: false,
            saving: false,
            saved: false,
            deleting: false,
            drag: false,
            availableMenuItems: [],
            menuItems: [],
            menuItem: {},
            editingMenuItem: false,
            savingMenuItem: false,
            saveMenuItemMessage: '',
            saveCount: 1
            // availableMenuItems: [
            //     { name: 'Menu Item One', id: 1 },
            //     { name: 'Menu Item Two', id: 2 },
            //     { name: 'Menu Item Three', id: 3 },
            //     { name: 'Menu Item Four', id: 4 },
            //     { name: 'Menu Item Five', id: 5 }
            // ],
            // menuItems: [
            //     { name: 'Menu Item Six', id: 6 },
            //     { name: 'Menu Item Seven', id: 7 },
            //     { name: 'Menu Item Eight', id: 8 },
            //     { name: 'Menu Item Nine', id: 9 },
            //     { name: 'Menu Item Ten', id: 10 }
            // ]
        }
    },
    created() {
        this.showFilters = window.matchMedia("(min-width: 768px)").matches
        this.getMenus()
    },
    computed: {
        // mix the getters into computed with object spread operator
        ...mapGetters([
            'getUser'
        ])
    },
	methods: {
        ...mapActions([
            'apiCall', // map `this.apiCall()` to `this.$store.dispatch('apiCall')`
        ]),
        getMenus() {
		    this.message = ''
			this.loading = true
    		this.apiCall({ destination: 'get_list_menus' }).then(obj => {
				this.loading = false
    			if (obj.status == 'success') {
					this.menus = obj.menus
    			} else {
					this.message = obj.message
				}
    		})
        },
        getItem(isNew) {
		    this.message = ''
            this.availableMenuItems = []
            this.menuItmes = []
            this.detail = {}
            if (!this.selectedMenuId && !isNew) {
                return
            }
			this.loading = true
    		this.apiCall({ destination: 'get_item_menu_items', data: { menu_id: this.menuId } }).then(obj => {
				this.loading = false
    			if (obj.status == 'success') {
                    for (let i = 0; i < this.menus.length; i++) {
                        if (this.menus[i].menu_id == this.selectedMenuId) {
                            this.detail = this.menus[i]
                            break;
                        }
                    }
                    // convert lists to draggable lists
                    obj.available_menu_items.forEach(menuItem => this.availableMenuItems.push( { id: menuItem.menu_item_id, name: menuItem.label + ' > ' + menuItem.link_value } ))
                    obj.menu_items.forEach(menuItem => this.menuItems.push( { id: menuItem.menu_item_id, name: menuItem.label + ' > ' + menuItem.link_value } ))
                    window.scrollTo(0, 0);
    			} else {
					this.message = obj.message
				}
    		})
        },
        newMenuItem() {
			this.saveMenuItemMessage = ''
            this.menuItem = {
                menu_item_id: 'new',
                label: '',
                link_type: '',
                link_value: '',
                sub_menu_id: ''
            }
            this.editingMenuItem = true
        },
		saveItem() {
			if (this.saving || !this.selectedMenuId) { return }
			this.message = ''
            this.saveCount++ // forces save message to relaod
            this.saving = true
		},
        deleteMenu() {
            if (this.deleting || !this.selectedMenuId) { return }
            this.deleting = true
            console.log('deleting')
        },
		saveMenuItem() {
			if (this.savingMenuItem) { return }
			this.saveMenuItemMessage = ''
            this.saveCount++ // forces save message to relaod
            if (!this.menuItem.label || !this.menuItem.link_type || !this.menuItem.link_value) {
                this.saveMenuItemMessage = 'Complete all required fields.'
                return
            }
            this.savingMenuItem = true
    		this.apiCall({ destination: 'save_item_menu_item', data: this.menuItem }).then(obj => {
				this.savingMenuItem = false
    			if (obj.status == 'success') {
                    // TODO: what happens with a newly saved menu item?
    			} else {
					this.saveMenuItemMessage = obj.message
				}
            })
		},
        deleteMenuItem() {

        },
        cancelEditItem() {
            this.selectedMenuId = ''
            this.availableMenuItems = []
            this.menuItmes = []
            this.detail = {}
        },
        backToList() {

        }
    }
}
</script>
<style lang="scss" scoped>
.draggable-list-heading {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    label.note { font-size: 0.8em; text-align: right; margin: 0 0.5em 0 0; }
}
.draggable-list {
    border: 1px solid #ccc;
    height: 20em;
    .draggable-list-item {
        padding: 0.25em;
        border: 1px solid #ccc;
        cursor: default;
        .handle {
            float: right;
            padding: 0.25em;
            color: #ccc;
            cursor: grab;
        }
    }
    .draggable-list-item:active {
        color: #ccc;
    }
    .ghost {
        opacity: 0.5;
        background: #c8ebfb;
    }
    &.list-right {
        .draggable-list-item {
            .handle {
                float: left;
                padding: 0.25em 0.5em 0.25em 0.25em;
            }
        }
    }
}

</style>
