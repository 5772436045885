<template>
	<div class="delete-button-group">
		<div :class="['button', 'button-dark', 'button-delete', { disabled: disabled }]" :style="'width: ' + labelWidth" @click="showConfirm()">
			<font-awesome-icon icon="cog" class="spin" v-if="loading" />
			<span v-else>{{ label }}</span>
		</div>
		<div v-if="confirming" class="confirm-delete">
			<label>Are you sure?</label>
			<div class="button delete button-confirm" @click="confirmDelete()">Yes</div>
			<div class="button button-dark button-confirm" @click="confirming = false">No</div>
		</div>
	</div>
</template>
<script>
export default {
	name: 'delete-button',
	props: ['label', 'loading', 'disabled'],
	data() {
		return {
			confirming: false,
			confirm: false
		}
	},
    computed: {
		labelWidth() {
			return this.label.length + 'em';
		}
    },
	methods: {
		showConfirm() {
			if (this.disabled) { return }
			this.confirming = !this.confirming
		},
		confirmDelete() {
			this.confirming = false
			this.$emit('doDelete');
		}
	}
}
</script>
<style lang="scss" scoped>
.delete-button-group { display: inline-block; }
.confirm-delete {
	display: inline-block;
	label { display: inline-block; margin: 0; }
	.button-confirm { margin: 0 0 0 1em; }
}
</style>
